import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  Brightness7Rounded,
  ConstructionRounded,
  FilterFramesRounded,
  FlareRounded,
  LayersRounded,
  PublicRounded,
  SecurityRounded,
  VerifiedUserRounded,
} from "@mui/icons-material";
import frameCloseup1Webp from "../../assets/images/frame-closeups-webp/01.webp";
import frameCloseup2Webp from "../../assets/images/frame-closeups-webp/02.webp";
import frameCloseup3Webp from "../../assets/images/frame-closeups-webp/03.webp";
import frameCloseup4Webp from "../../assets/images/frame-closeups-webp/04.webp";
import frameCloseup5Webp from "../../assets/images/frame-closeups-webp/05.webp";
import frameCloseup6Webp from "../../assets/images/frame-closeups-webp/06.webp";
import paperCloseup1 from "../../assets/images/paper-closeups-webp/01.png";
import paperCloseup2 from "../../assets/images/paper-closeups-webp/02.png";
import Switch from "../../components/Switch";
import { colors } from "../../theme";
import { useAppState } from "../../state";

const QualitySection = () => {
  const { isMobileScreen } = useAppState();
  const [selectedSwitchOption, setSelectedSwitchOption] = useState("frames");
  const height = isMobileScreen ? "40vh" : "60vh";

  return (
    <Stack paddingX={{ xs: 2, md: 5 }} direction={{ xs: "column", md: "row" }} gap={6}>
      <Box width={{ xs: "100%", md: "30%" }}>
        <Typography variant="h2">Our exceptional quality</Typography>
        <Box paddingTop={3} paddingBottom={4}>
          <Switch
            options={[
              { label: "Frames", value: "frames" },
              { label: "Paper", value: "paper" },
            ]}
            selected={selectedSwitchOption}
            onChange={(value) => setSelectedSwitchOption(value as "frames" | "paper")}
            fullWidth
          />
        </Box>
        <Box>
          {selectedSwitchOption === "frames" ? (
            <Stack gap={3}>
              <Stack gap={1.5} direction="row">
                <ConstructionRounded />
                <Stack gap={0.2}>
                  <Typography>Handcrafted wooden frames</Typography>
                  <Typography fontSize={14} color={colors.grey60}>
                    Meticulous, gallery-grade craftsmanship
                  </Typography>
                </Stack>
              </Stack>

              <Stack gap={1.5} direction="row">
                <PublicRounded />
                <Stack gap={0.2}>
                  <Typography>Sustainable, FSC certified wood</Typography>
                  <Typography fontSize={14} color={colors.grey60}>
                    Eco-friendly choice supporting responsible forestry
                  </Typography>
                </Stack>
              </Stack>

              <Stack gap={1.5} direction="row">
                <SecurityRounded />
                <Stack gap={0.2}>
                  <Typography>UV-blocking, premium glaze</Typography>
                  <Typography fontSize={14} color={colors.grey60}>
                    Ensure your art remains vibrant for 100+ years
                  </Typography>
                </Stack>
              </Stack>

              <Stack gap={1.5} direction="row">
                <FilterFramesRounded />
                <Stack gap={0.2}>
                  <Typography>Ready-to-hang, with archival sealing</Typography>
                  <Typography fontSize={14} color={colors.grey60}>
                    Acid-free materials protect from dust and moisture
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack gap={3}>
              <Stack gap={1.5} direction="row">
                <Brightness7Rounded />
                <Stack gap={0.2}>
                  <Typography>We’re Giclée printing experts</Typography>
                  <Typography fontSize={14} color={colors.grey60}>
                    Industry leading 12-colour ultra-chrome HDR inks
                  </Typography>
                </Stack>
              </Stack>

              <Stack gap={1.5} direction="row">
                <LayersRounded />
                <Stack gap={0.2}>
                  <Typography>Luxurious 200gsm, heavyweight paper</Typography>
                  <Typography fontSize={14} color={colors.grey60}>
                    Fine art paper with a sleek matte finish
                  </Typography>
                </Stack>
              </Stack>

              <Stack gap={1.5} direction="row">
                <FlareRounded />
                <Stack gap={0.2}>
                  <Typography>Rich, vibrant colours</Typography>
                  <Typography fontSize={14} color={colors.grey60}>
                    Bright colours that pop, with rich, deep dark tones
                  </Typography>
                </Stack>
              </Stack>

              <Stack gap={1.5} direction="row">
                <VerifiedUserRounded />
                <Stack gap={0.2}>
                  <Typography>Museum quality, made to order</Typography>
                  <Typography fontSize={14} color={colors.grey60}>
                    Ensuring only the finest, with zero waste
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
      </Box>
      {selectedSwitchOption === "frames" ? (
        <Box width={{ xs: "100%", md: "70%" }}>
          <Stack
            gap={{ xs: 1.5, md: 2 }}
            overflow="auto"
            direction="row"
            marginRight={{ xs: -2, md: -5 }}
            marginLeft={{ xs: -2, md: 0 }}
            paddingRight={{ xs: 2, md: 5 }}
            paddingLeft={{ xs: 2, md: 0 }}
          >
            <img src={frameCloseup1Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
            <img src={frameCloseup2Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
            <img src={frameCloseup3Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
            <img src={frameCloseup4Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
            <img src={frameCloseup5Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
            <img src={frameCloseup6Webp} alt="frame close-up" width="auto" height="auto" style={{ height }} />
          </Stack>
        </Box>
      ) : (
        <Box width={{ xs: "100%", md: "70%" }}>
          <Stack
            gap={{ xs: 1.5, md: 2 }}
            overflow="auto"
            direction="row"
            marginRight={{ xs: -2, md: -5 }}
            marginLeft={{ xs: -2, md: 0 }}
            paddingRight={{ xs: 2, md: 5 }}
            paddingLeft={{ xs: 2, md: 0 }}
          >
            <img src={paperCloseup1} alt="frame close-up" width="auto" height="auto" style={{ height }} />
            <img src={paperCloseup2} alt="frame close-up" width="auto" height="auto" style={{ height }} />
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default QualitySection;
