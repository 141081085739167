import { ChangeEvent, useRef, useState } from "react";
import { match } from "ts-pattern";
import { format } from "date-fns";
import { IconButton, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import Button from "../../../../components/Button";
import { createInvoicePdf, markInvoiceAsPaid, sendInvoiceEmail } from "../../helpers/invoices";
import { getInvoiceSubtotal } from "../../../../helpers/prices";
import { InvoiceStatus } from "../../../../types/invoice";
import { createPayout } from "../../../../services/API";
import InvoicePdf from "./InvoicePdf";
import { InvoiceRow } from ".";
import { deleteInvoice, updateInvoice } from "../../../../services/Firebase/invoices";
import { CurrencyCode } from "../../../../generated/storefront";
import { updateUser } from "../../../../services/Firebase/users";
import Checkbox from "../../../../components/Checkbox";

type Props = {
  row: InvoiceRow;
  fetchInvoices: () => Promise<void>;
};

const InvoiceActionButtons = ({ row, fetchInvoices }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const currencies = Object.values(CurrencyCode);

  const onCurrencyChange = async ({ target }: SelectChangeEvent<CurrencyCode>) => {
    await updateUser(row.artistId, { currency: target.value as CurrencyCode });
  };

  const onPayByBankChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    await updateUser(row.artistId, { payByBank: target.checked });
  };

  const onApprove = async () => {
    setLoading(true);
    if (ref.current) {
      try {
        await createInvoicePdf(row.id, row.invoiceNumber, ref.current);
        await updateInvoice(row.id, { status: InvoiceStatus.APPROVED });
        fetchInvoices();
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        alert("Something went wrong, please try again.");
      }
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await deleteInvoice(row.id);
      fetchInvoices();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      alert("Something went wrong, please try again.");
    }
  };

  const markAsPaid = async () => {
    setLoading(true);
    try {
      await fetchInvoices();
      await markInvoiceAsPaid(row.id);
      await sendInvoiceEmail(row);
      fetchInvoices();
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      alert(`Something went wrong: ${error?.message}`);
    }
  };

  const onPay = async () => {
    if (!row.paypalEmail) {
      return alert("No paypal email address found for this artist.");
    }
    setLoading(true);
    try {
      await createPayout({
        email: row.paypalEmail,
        amount: getInvoiceSubtotal(row),
        month: format(row.issueDate.toDate(), "MMMM yyyy"),
        invoiceNumber: row.invoiceNumber,
        currency: row.currency,
      });
      await markAsPaid();
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      alert(`Something went wrong - ${error?.message}`);
    }
  };

  const onResendEmail = async () => {
    setLoading(true);
    await sendInvoiceEmail(row);
    setLoading(false);
  };

  return match(row.status)
    .with(InvoiceStatus.DRAFT, () => (
      <>
        <Stack direction="row" gap={1} alignItems="center">
          <Button size="small" onClick={onApprove} loading={loading}>
            Approve
          </Button>
          <IconButton size="small" onClick={onDelete}>
            <Delete fontSize="small" />
          </IconButton>
        </Stack>
        <div style={{ marginLeft: "200px" }}>
          <InvoicePdf ref={ref} invoiceData={row} />
        </div>
      </>
    ))
    .with(InvoiceStatus.APPROVED, () => (
      <Stack gap={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography fontSize={14} fontWeight={600}>
            Pay automatically:
          </Typography>
          <Select size="small" variant="filled" onChange={onCurrencyChange} value={row.currency} style={{ minWidth: "100px" }}>
            {currencies.map((c) => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
          </Select>
          <Button size="small" onClick={onPay} loading={loading} fullWidth>
            Pay
          </Button>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography fontSize={14} fontWeight={600}>
            Pay manually:
          </Typography>
          <Checkbox label="Pay by bank" onChange={onPayByBankChange} defaultChecked={row.payByBank} />
          <Button size="small" onClick={markAsPaid} loading={loading} fullWidth>
            Mark as paid
          </Button>
        </Stack>
      </Stack>
    ))
    .with(InvoiceStatus.PAID, () => (
      <Button size="small" onClick={onResendEmail} loading={loading}>
        Resend email
      </Button>
    ))
    .exhaustive();
};

export default InvoiceActionButtons;
