import { BasketFragment } from "../generated/storefront";

export const getBasketDiscountPct = (basket?: BasketFragment): number => {
  if (!basket || !basket.lines || !basket.lines.nodes.length) {
    return 0;
  }
  const firstItem = basket.lines.nodes[0];
  const discountPct =
    basket && firstItem.discountAllocations.length
      ? firstItem.discountAllocations[0].discountedAmount.amount / firstItem.cost.subtotalAmount.amount
      : 0;

  return discountPct;
};

export const getFutureDiscountPct = (basket?: BasketFragment): number => {
  const basketDiscoutPct = getBasketDiscountPct(basket);

  if (basketDiscoutPct === 0.15) {
    return 0.2;
  }
  if (basket && basket.lines.nodes.length > 0 && basketDiscoutPct === 0) {
    return 0.15;
  }
  return 0;
};
