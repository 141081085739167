import styled from "styled-components";
import { Box } from "@mui/material";
import { colors } from "../../theme";

export const BadgeButton = styled.button<{ size: "small" | "large"; selected: boolean; disabled?: boolean }>`
  position: relative;
  border-radius: 100%;
  border: ${({ selected }) => (selected ? `2px solid ${colors.white}` : "0")};
  background: none;
  padding: 0;
  height: ${(p) => (p.size === "small" ? 26 : 32)}px;
  width: ${(p) => (p.size === "small" ? 26 : 32)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.2;
  `}

  img {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border: 2px solid ${(p) => (p.selected ? colors.black : "transparent")};
  }

  &:hover {
    border: 2px solid ${colors.white};
  }

  &:hover::after {
    border-color: ${colors.grey80};
  }
`;

export const FiltersButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${colors.black};
`;

export const StickyContainer = styled(Box)<{ navHeight: number }>`
  position: sticky;
  top: ${(p) => p.navHeight}px;
  z-index: 1;
`;
