import { PropsWithChildren, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
// @ts-ignore
import { Markup } from "react-render-markup";
import { capitalize, compact } from "lodash";
import styled from "styled-components";
import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import { CheckRounded, Facebook, LinkRounded, LinkedIn, Pinterest, X } from "@mui/icons-material";
import {
  AristProductFragment,
  CollectionByHandleFragment,
  ProductCardProductFragment,
  ProductSortKeys,
  useGetArtistProductsLazyQuery,
  useGetBlogArticleQuery,
  useGetCollectionByHandleLazyQuery,
} from "../../generated/storefront";
import { Image } from "../../components";
import { Loader } from "../../components/Loader";
import { media } from "../../helpers/layout";
import { useAppState } from "../../state";
import { getUserByPermalink } from "../../services/Firebase/users";
import ScrollCarousel from "../../components/ScrollCarousel";
import ProductCard from "../../components/ProductCard";
import { User } from "../../types/user";
import { Frame } from "../../types/product";
import { colors } from "../../theme";
import { appendArtistsToProducts } from "../../helpers/product";

const BlogArticleContent = styled(Stack)`
  p:not(:has(img)),
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0 32px;
    width: 50%;
  }

  ol p {
    padding: 0;
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 600;
  }

  a,
  p,
  div,
  li,
  strong {
    font-size: 16px;
    line-height: 150%;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }

  img {
    width: 100%;
    max-height: 100vh;
    object-fit: cover;
  }

  a {
    text-decoration: underline;
    underline-offset: 2px;
  }

  ul,
  ol {
    width: 50%;
    padding: 0 32px 0 48px;
  }

  ul p,
  ol p {
    padding: 0 4px;
  }

  @media ${media.m} {
    p:not(:has(img)),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 16px;
      width: 100%;
    }

    ul,
    ol {
      width: 100%;
      padding: 0 16px 0 36px;
    }

    ul p,
    ol p {
      padding: 0 4px;
    }
  }
`;

type ArtistProductWithArtist = AristProductFragment & { artist: User | null };

const ShareIcon = ({ onClick, children }: PropsWithChildren<{ onClick: () => void }>) => {
  return (
    <IconButton
      onClick={onClick}
      color="primary"
      style={{ display: "flex", width: 32, height: 32, fontSize: 16, padding: 0, backgroundColor: colors.cardGrey }}
    >
      {children}
    </IconButton>
  );
};

const Blog = () => {
  const { id } = useParams();
  const { selectedCountry } = useAppState();
  const [copied, setCopied] = useState(false);
  const [artistProducts, setArtistProducts] = useState<ArtistProductWithArtist[]>([]);
  const { data } = useGetBlogArticleQuery({
    variables: {
      id: id ? `gid://shopify/Article/${id}` : "",
    },
  });
  const article = data?.article;
  const [getCollection] = useGetCollectionByHandleLazyQuery();
  const [getArtistProducts] = useGetArtistProductsLazyQuery();
  const [collections, setCollections] =
    useState<
      (Omit<CollectionByHandleFragment, "products"> & { products: (ProductCardProductFragment & { artist: User | null })[] })[]
    >();
  const text = "GoodNews by GoodMood - check it out!";
  const url = window.location.href;

  const onShare = () => {
    if (navigator.share) {
      if (article) {
        navigator.share({
          text,
          url,
        });
      }
    } else {
      navigator.clipboard.writeText(window.location.href);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const onShareToX = () => {
    const shareUrl = `https://x.com/intent/post?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
    window.open(shareUrl, "_blank");
  };

  const onShareToFacebook = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}`;
    window.open(shareUrl, "_blank");
  };

  const onShareToLinkedIn = () => {
    const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(
      text
    )}`;
    window.open(shareUrl, "_blank");
  };

  const onShareToPinterest = () => {
    const shareUrl = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
      url
    )}&description=${encodeURIComponent(text)}`;
    window.open(shareUrl, "_blank");
  };

  const getProductsForArtist = async () => {
    if (article && article.artistPermalink) {
      const artist = await getUserByPermalink(article.artistPermalink.value);
      const result = await getArtistProducts({
        variables: {
          limit: 12,
          query: `vendor:${artist.id}`,
          sortKey: ProductSortKeys.CreatedAt,
          country: selectedCountry,
        },
      });
      if (result.data) {
        setArtistProducts(result.data.products.nodes.map((product) => ({ ...product, artist })));
      }
    }
  };

  const getCollections = async () => {
    if (article && article.collectionHandles) {
      const collectionHandles: string[] = JSON.parse(article.collectionHandles.value);
      const collectionPromises = collectionHandles.map(async (handle) => {
        const result = await getCollection({
          variables: {
            handle,
            country: selectedCountry,
          },
        });

        if (result.data?.collection) {
          const collectionProducts = await appendArtistsToProducts(result.data.collection.products.nodes || []);
          return { ...result.data.collection, products: collectionProducts };
        }
      });
      const collectionsToShow = await Promise.all(collectionPromises);
      setCollections(compact(collectionsToShow));
    }
  };

  useEffect(() => {
    getProductsForArtist();
    getCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article, selectedCountry]);

  return article ? (
    <div>
      <Helmet>
        <title>{article.title || "GoodNews by GoodMood"}</title>
        <meta name="description" content={article.excerpt || ""} />
      </Helmet>
      <Box>
        <Stack
          gap={3}
          paddingX={2}
          paddingTop={4}
          paddingBottom={{ xs: 4, md: 8 }}
          bgcolor={article.backgroundColour?.value}
          height={{ xs: "40%", md: "auto" }}
          alignItems="center"
          justifyContent="center"
        >
          <Stack gap={2} alignItems="center" width={{ xs: "100%", md: "60%" }}>
            <Typography variant="h1" align="center" fontSize={{ xs: 32, md: 64 }}>
              {article.title}
            </Typography>
            <Typography variant="subtitle1" fontSize={{ xs: 14, md: 20 }} align="center">
              {article.excerpt}
            </Typography>

            <Stack direction="row" gap={0.5}>
              {article.tags.map((tag) => (
                <Link key={tag} to={`/goodnews?tag=${tag}`}>
                  <Chip
                    label={capitalize(tag)}
                    size="small"
                    sx={{ color: colors.darkPurple, backgroundColor: colors.purple20, fontSize: 12, borderRadius: 1 }}
                  />
                </Link>
              ))}
            </Stack>
          </Stack>

          <Stack gap={1} direction="row" alignItems="center" justifyContent="center">
            <ShareIcon onClick={onShare}>
              {copied ? <CheckRounded fontSize="inherit" /> : <LinkRounded fontSize="inherit" />}
            </ShareIcon>
            <ShareIcon onClick={onShareToX}>
              <X fontSize="inherit" />
            </ShareIcon>
            <ShareIcon onClick={onShareToFacebook}>
              <Facebook fontSize="inherit" />
            </ShareIcon>
            <ShareIcon onClick={onShareToLinkedIn}>
              <LinkedIn fontSize="inherit" />
            </ShareIcon>
            <ShareIcon onClick={onShareToPinterest}>
              <Pinterest fontSize="inherit" />
            </ShareIcon>
          </Stack>
        </Stack>

        <Stack height={{ xs: "60%", md: "auto" }} maxHeight="100vh" overflow="hidden">
          <Image src={article.image?.url} alt={article.image?.altText || "blog"} />
        </Stack>
      </Box>
      <BlogArticleContent paddingY={6} alignItems="center">
        <Markup markup={article.contentHtml} style={{ margin: 0 }} />
      </BlogArticleContent>

      {artistProducts.length > 0 && artistProducts[0].artist && (
        <Box paddingTop={{ xs: 0, md: 2 }} paddingBottom={6}>
          <ScrollCarousel header={`More from ${artistProducts[0].artist.firstName} ${artistProducts[0].artist.lastName}`}>
            {artistProducts?.map((product) => (
              <ProductCard product={product} artist={product.artist} key={product.id} frame={Frame.Natural} />
            )) || []}
          </ScrollCarousel>
        </Box>
      )}

      {collections &&
        collections.map((collection) => (
          <Box paddingTop={{ xs: 0, md: 2 }} paddingBottom={6}>
            <ScrollCarousel header={collection.title} cta={{ text: "View all", link: `/shop/collections/${collection.handle}` }}>
              {collection.products?.map((product) => (
                <ProductCard product={product} artist={product.artist} key={product.id} frame={Frame.Natural} />
              )) || []}
            </ScrollCarousel>
          </Box>
        ))}
    </div>
  ) : (
    <Loader />
  );
};

export default Blog;
