import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { capitalize } from "lodash";
import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { CloseRounded, InfoOutlined } from "@mui/icons-material";
import { Frame, Size, SizeScale } from "../../types/product";
import { Container } from "../../components/Layout";
import { formattedPrice } from "../../helpers/money";
import Button from "../../components/Button";
import { User } from "../../types/user";
import RadioButton from "../../components/Radio/button";
import { useAppState } from "../../state";
import { isNextMonth } from "../../helpers/time";
import { media } from "../../helpers/layout";
import { colors } from "../../theme";
import AuthContext from "../../state/auth";
import { getAvailableSizes, sizeLabelsForCountry } from "../../helpers/product";
import InfoAccordion from "./InfoAccordion";
import { giftCardId, scrollbarStyles } from "../../state/constants";
import { ProductPageProductFragment } from "../../generated/storefront";
import Cards from "./Cards";
import useBasketActions from "../../hooks/useBasketActions";
import FrameBadges from "../../components/FrameBadges";
import WishlistBookmark from "../../components/WishlistBookmark";
import mounting from "../../assets/images/mount.png";
import { BadgeButton } from "../Shop/styles";
import whiteFrameBadge from "../../assets/images/whiteFrameBadge.svg";
import unframedBadge from "../../assets/images/unframedBadge.svg";
import { mountingPrices } from "../../helpers/prices";
import { getFutureDiscountPct } from "../../helpers/basket";

export type CheckoutState = {
  quantity: number;
  size?: Size;
  frame?: Frame;
  mounted: boolean;
  denominations?: string;
} & { [key: string]: string | number | boolean };

type Props = {
  product: ProductPageProductFragment;
  artist?: User | null;
  state: CheckoutState;
  setState: (state: CheckoutState) => void;
  setEdited: (edited: boolean) => void;
};

const StickySection = styled(Container)<{ sticky: boolean; navHeight: number }>`
  position: ${(p) => (p.sticky ? "sticky" : "relative")};
  top: ${(p) => (p.sticky ? `${p.navHeight}px` : "0")};
  z-index: 1;
  background: ${colors.white};

  @media ${media.m} {
    position: relative;
    top: 0;
  }
`;

const SideBar = ({ product, artist, state, setState, setEdited }: Props) => {
  const { isAdmin, customer, refetchCustomer } = useContext(AuthContext);
  const { basket, selectedCountry, navHeight, isMobileScreen } = useAppState();
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const variants = product.variants.nodes;
  const { addOrCreateBasket, loading, error } = useBasketActions();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const popoverOpen = Boolean(anchorEl);
  const selectedVariant = variants.find((variant) =>
    variant.selectedOptions.every((option) => {
      return state[option.name] === option.value;
    })
  );

  const addToBasket = () => {
    if (selectedVariant && state.size) {
      const lines = [
        {
          merchandiseId: selectedVariant.id,
          quantity: state.quantity,
          size: state.size,
          attributes: state.mounted
            ? [
                {
                  key: "mounted",
                  value: "yes",
                },
              ]
            : [],
        },
      ];
      addOrCreateBasket(lines);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
    setEdited(true);
  };

  const handleFrameChange = (frame: Frame) => {
    setState({ ...state, frame });
  };

  const handleMountingChange = (mounted: boolean) => {
    setState({ ...state, mounted });
  };

  useEffect(() => {
    const sizeOptions = variants.map((v) => v.selectedOptions.find((o) => o.name === "size")?.value as Size);
    const defaultSize = sizeOptions.find((o) => SizeScale[o] === 1);
    if (defaultSize) {
      setState({ ...state, size: defaultSize, frame: Frame.Natural });
    } else {
      const defaultVariant = variants[0].selectedOptions;
      const { name, value } = defaultVariant[0];
      setState({ ...state, [name]: value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants]);

  useEffect(() => {
    if (state.frame === Frame.Unframed) {
      setState({ ...state, mounted: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.frame]);

  const giftCard = product.id === giftCardId;
  const discountPct = getFutureDiscountPct(basket);
  const showDiscountedPrices = discountPct > 0;
  const currencyCode = product.variants.nodes[0].price.currencyCode;
  const selectedVariantPrice =
    Number(selectedVariant?.price.amount) + (state.mounted && state.size ? mountingPrices[state.size] : 0);

  if (giftCard) {
    return (
      <Stack width={{ xs: "100%", md: "33%" }}>
        <StickySection sticky={!itemIsOpen} navHeight={navHeight}>
          <Box paddingX={{ xs: 2, md: 3 }} paddingTop={{ xs: 1.5, md: 0 }}>
            <Stack gap={2} height="100%">
              <Stack gap={1}>
                <Typography fontSize={{ xs: 12, md: 16 }}>Amount</Typography>
                <Stack onChange={handleChange} direction="row" overflow="auto" gap={1} sx={scrollbarStyles}>
                  {variants.map((v) => {
                    const denomationValue = formattedPrice(v.price.amount, currencyCode);
                    return (
                      <RadioButton
                        name="denominations"
                        key={denomationValue}
                        label={denomationValue}
                        id={v.price.amount}
                        checked={state.denominations === denomationValue}
                        value={denomationValue}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              <Stack paddingBottom={3} gap={1.5}>
                <Button fullWidth onClick={addToBasket} disabled={!state.denominations} loading={loading} size="large">
                  Add to basket
                </Button>
                {error && <Typography color={colors.error}>{error}</Typography>}
              </Stack>
            </Stack>
          </Box>
        </StickySection>
      </Stack>
    );
  }

  const sizeLabels = sizeLabelsForCountry(selectedCountry);
  const availabileSizes = getAvailableSizes(product.variants.nodes);

  if (!selectedVariant) return null;

  return (
    <Stack width={{ xs: "100%", md: "33%" }}>
      <Stack gap={2} height="100%">
        {artist && (
          <Box paddingX={{ xs: 2, md: 3 }} paddingTop={{ xs: 1.5, md: 0 }}>
            <Stack justifyContent="space-between" direction="row" gap={3}>
              <Stack gap={0.5}>
                {isMobileScreen ? (
                  <Typography component="h1">{product.title}</Typography>
                ) : (
                  <Stack gap={1} direction="row" alignItems="center">
                    <Typography variant="h2" component="h1">
                      {product.title}
                    </Typography>
                  </Stack>
                )}

                <Link to={`/artists/${artist.permalink || artist.id}`}>
                  <Typography fontSize={{ xs: 14, md: 16 }} style={{ textDecoration: "underline" }}>
                    {artist.firstName} {artist.lastName}
                  </Typography>
                </Link>
              </Stack>
              {isMobileScreen && (
                <Stack gap={0.5} alignItems="end">
                  <Stack gap={1.5} direction="row">
                    <Typography
                      style={{ whiteSpace: "nowrap", textDecoration: showDiscountedPrices ? "line-through" : "none" }}
                      color={showDiscountedPrices ? "text.disabled" : "primary"}
                      align="right"
                    >
                      {formattedPrice(selectedVariantPrice, currencyCode)}
                    </Typography>
                    {showDiscountedPrices && (
                      <Typography color={colors.red} align="right" style={{ whiteSpace: "nowrap" }}>
                        {formattedPrice(Number(selectedVariantPrice) * (1 - discountPct), currencyCode)}
                      </Typography>
                    )}
                  </Stack>
                  <WishlistBookmark
                    productId={product.id}
                    customer={customer}
                    refetchCustomer={refetchCustomer}
                    popoverOpen={popoverOpen}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                  />
                </Stack>
              )}
            </Stack>
          </Box>
        )}
        {isNextMonth(product.productType) && !isAdmin ? (
          <Box paddingX={{ xs: 2, md: 3 }}>
            <Typography>This product is coming in next month's collection</Typography>
          </Box>
        ) : (
          <>
            <StickySection sticky={!itemIsOpen} navHeight={navHeight}>
              <Box paddingX={{ xs: 2, md: 3 }}>
                <Stack gap={2} height="100%">
                  <Stack gap={5} direction="row">
                    <Stack gap={1}>
                      <Typography fontSize={{ xs: 12, md: 14 }}>Frame: {capitalize(state.frame)}</Typography>
                      <FrameBadges
                        selectedFrame={state.frame || Frame.Natural}
                        setSelectedFrame={handleFrameChange}
                        size="large"
                      />
                    </Stack>

                    <Stack gap={1}>
                      <Typography fontSize={{ xs: 12, md: 14 }} display="flex" alignItems="center">
                        Mount: {state.mounted ? "Yes" : "No"}
                        <InfoOutlined
                          onClick={() => setDialogOpen(true)}
                          fontSize="inherit"
                          style={{ cursor: "pointer", marginLeft: 4, color: colors.grey40 }}
                        />
                      </Typography>
                      <Stack direction="row" gap={1.25}>
                        <BadgeButton
                          selected={state.mounted}
                          disabled={state.frame === Frame.Unframed}
                          onClick={() => handleMountingChange(true)}
                          size="large"
                        >
                          <img src={whiteFrameBadge} alt="black frame icon badge" width={32} height={32} />
                        </BadgeButton>
                        <BadgeButton
                          selected={!state.mounted}
                          disabled={state.frame === Frame.Unframed}
                          onClick={() => handleMountingChange(false)}
                          size="large"
                        >
                          <img src={unframedBadge} alt="unframed icon badge" width={32} height={32} />
                        </BadgeButton>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack gap={3}>
                    <Stack gap={1}>
                      <Typography fontSize={{ xs: 12, md: 14 }}>
                        Size:{" "}
                        {state.size
                          ? sizeLabels[state.size][
                              state.frame === Frame.Unframed ? ("printMeasurements" as const) : ("framedMeasurements" as const)
                            ]
                          : ""}
                      </Typography>
                      <Stack onChange={handleChange} direction="row" gap={1}>
                        {availabileSizes.map((size) => (
                          <RadioButton
                            name="size"
                            key={size}
                            label={sizeLabels[size].shortHand}
                            id={size}
                            checked={state.size === size}
                            value={size}
                          />
                        ))}
                      </Stack>
                    </Stack>

                    {!isMobileScreen && (
                      <Stack gap={1.5} direction="row" alignItems="center">
                        <Typography
                          color={showDiscountedPrices ? "text.disabled" : "primary"}
                          style={{ whiteSpace: "nowrap", textDecoration: showDiscountedPrices ? "line-through" : "none" }}
                        >
                          {formattedPrice(selectedVariantPrice, currencyCode)}
                        </Typography>
                        {showDiscountedPrices && (
                          <Typography color={colors.red}>
                            {formattedPrice(Number(selectedVariantPrice) * (1 - discountPct), currencyCode)} · {discountPct * 100}
                            % OFF
                          </Typography>
                        )}
                      </Stack>
                    )}
                  </Stack>
                  <Stack paddingBottom={1} gap={1.5}>
                    <Button
                      fullWidth
                      onClick={addToBasket}
                      disabled={!(state.size && state.frame && state.quantity)}
                      loading={loading}
                      size="large"
                    >
                      Add to basket
                    </Button>
                    <Typography variant="caption" align="center" color="text.secondary" fontSize={{ xs: 12, md: 14 }}>
                      No import fees for UK, EU, AUS & USA
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </StickySection>

            <div>
              <Cards />
              <InfoAccordion product={product} artist={artist} setItemIsOpen={setItemIsOpen} />
            </div>
          </>
        )}

        <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
          <CloseRounded
            onClick={() => setDialogOpen(false)}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: 0,
              right: 0,
              margin: 2,
              background: colors.white,
              borderRadius: "100%",
              padding: "4px",
            }}
          />
          <Box height={300} width="100%" display="flex" justifyContent="center" alignItems="center">
            <img src={mounting} alt="mounting" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          </Box>
          <DialogContent>
            <Stack gap={1}>
              <Typography>Mounting</Typography>
              <Typography fontSize={12}>
                Enhance your framed piece with a 2 inch mount around your print. The card is a conservation-grade, 2.4mm thick
                mount board with bevelled edges. Note that the mount will reduce the size of the print by 2 inches on each side.
              </Typography>
            </Stack>
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default SideBar;
